import React from "react";
import { graphql } from "gatsby";
import Page from "./page";
import Seo from "../components/seo";

const Career = ({ path, location, data: { mdx, allMdx } }) => {
  return (
    <>
      <Seo
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.description}
        pathname={path}
      />
      <Page location={location} mdx={mdx} allMdx={allMdx} />
    </>
  );
};
export default Career;

export const query = graphql`
  query Career($locale: String!, $slug: String!) {
    mdx(fields: { locale: { eq: $locale }, slug: { eq: $slug } }) {
      frontmatter {
        title
        description
      }
      body
    }
    allMdx(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "/(career)/.*.mdx$/" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
          }
          fields {
            slug
          }
        }
      }
    }
  }
`;
